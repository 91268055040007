import React from "react"
import useImages from "../hooks/useImages"
import Img from "gatsby-image"

import Section from "./section"
import Separator from "../components/separator"

import css from "./crew.css"

export default function Us() {
  const { 
    paris,
    hector,
    sergio,
    rodri,
    sara,
    irene,
    diana,
    meri 
  } = useImages()

  return (
    <Section>
      <div css={css}>
        <div className={"tile"}>
          <Img fluid={diana.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Diana</h3>
              <Separator />
            </div>
            <p>La más leal y antigua amiga de la novia. Y pensar que quería volver a Valencia, la novia agradece que se quedara en Madrid, ya que desde el instituto compartieron su amor por el arte, los videojuegos, las películas y las artes marciales. Vivieron juntas todo tipo de locuras y conocen sus más íntimos secretos. Pese a no separarse de su cámara, esta vez deja a su hermana a cargo de las fotos para poder cumplir su deber como dama.</p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={irene.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Irene</h3>
              <Separator />
            </div>
            <p>
              Alma de la fiesta, hábil tiradora y megáfono con patas. Amiga y compañera de aventuras de la novia: descensos del Sella, torneos, tiroteos y fartucadas varias. Cualquier plan es bueno para ella sin importar con quién. Desde Madrid, con su sonrisa permanente, viene a darlo todo. 
    	        PD. NO la incitéis a hacer locuras, de verdad, las hace por sí misma
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={sara.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Sara</h3>
              <Separator />
            </div>
            <p>
              Desde el primer momento que conocí a Sara sabía que congeniaríamos perfectamente. Resolutiva, ingeniosa y alegre. Si alguna vez me quedo encerrada en una habitación espero que sea con ella, es la mejor para ayudarme a escapar. Orgullosa madre de la portadora de los anillos (Líah). Mi ayudante de estilismo, confidente y compañera de viajes. También viene desde Madrid y agradezco tenerla a mi lado.
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={meri.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Meri</h3>
              <Separator />
            </div>
            <p>
              La última integrante de las damas de honor. Casi vecina de la novia, tan pronto comparten mesa en una barbacoa, pasean entre la naturaleza o disfrutan de una tarde en la playa. No os dejéis engañar por esa mirada tranquila y relajada, cuando habla es para dictar sentencia o hacer reír al personal con sus burradas. Bien podría hacer la tarta nupcial, pero prefiere mantener sus habilidades en secreto.
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={sergio.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Sergio</h3>
              <Separator />
            </div>
            <p>
              Érase una vez en el colegio el novio y Sergio se conocieron. ¿Cuándo? Ni ellos lo saben. Amigos y compañeros desde que el mundo es mundo, han compartido todas las etapas de sus vidas y se conocen mejor que nadie. Con un sentido del humor afilado cual katana. Hombre de confianza de nuestro novio. Viene desde Berlín para ser la punta de lanza de la guardia real. Willkomen, mein Freund!
            </p>
            <div></div>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={rodri.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Rodrigo</h3>
              <Separator />
            </div>
            <p>
              Hey, y esa guitarra? ¿Puedo tocar? Por cierto, yo soy Rodri. Esa fue la carta de presentación de nuestro guardián. Desde aquel 2008 hasta entonces hemos vivido muchísimas cosas juntos, incluso fue el guitarra solista durante el único concierto de nuestro legendario grupo de rock. Siempre con una sonrisa, siempre apoyando. No le den un ukelele, es peligroso.
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={paris.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Paris</h3>
              <Separator />
            </div>
            <p>
              Paris y nuestro novio se conocieron en Tenerife en un viaje de… trabajo podríamos decir! A nuestro guardián se le ocurrió decir que veraneaba en un pueblo enano de Asturias que no conocía nadie… y resulta que era Naveces. Después de ofrecerle un par de collejas el novio y él se hicieron grandes amigos. No le pidan fotos ni le hagan hablar en público, se siente más cómodo en la sombra
            </p>
          </div>
        </div>
        <div className={"tile"}>
          <Img fluid={hector.childImageSharp.fluid} alt=""></Img>
          <div className="tile__content">
            <div>
              <h3>Hector</h3>
              <Separator />
            </div>
            <p>
              Nos conocimos de niños, éramos jóvenes y nuestro tiempo se dividía en patinar, jugar a la pilla por medio pueblo y otras grandes ideas. Durante un tiempo nos vimos poco pero nos reencontramos en una barbacoa (Como no!) y a partir de ahí hemos disfrutado innumerables momentos. Manitas, gran persona, tremendo surfista (se autodenomina hijo del mar), y amante de la naturaleza. Es el último pero no menos importante guardián.
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
