import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import Layout from "../components/layout"
import Section from "../components/section"
import Separator from "../components/separator"
import Crew from "../components/crew"
import Playlist from "../components/playlist"

export default function Informacion({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section
        style={{
          margin: "5rem 0",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2>Nuestra gente</h2>
        <Separator />
      </Section>
      <div
        style={{
          maxWidth: "1000px",
          margin: "0 auto",
        }}
      >
        <Crew></Crew>
      </div>
      <Playlist />
    </Layout>
  )
}
